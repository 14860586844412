import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import SkuCard from "./SkuCard";

const containerStyles = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  padding: "1rem 0 1rem 0",
};

class Skus extends Component {
  // Initialise Stripe.js with your publishable key.
  // You can find your key in the Dashboard:
  // https://dashboard.stripe.com/account/apikeys
  state = {
    stripe: null,
  };
  componentDidMount() {
    const stripe = window.Stripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
    this.setState({ stripe });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SkusForProductRecords {
            skus: allStripeSku(
              sort: { order: DESC, fields: id }
              filter: { product: { id: { eq: "prod_H9p5CtSOuE6Kbi" } } }
            ) {
              edges {
                node {
                  id
                  currency
                  price
                  image
                  attributes {
                    name
                  }
                  localFiles {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                      fixed(width: 200) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ skus }) => (
          <div>
            <div style={containerStyles}>
              {skus.edges.map(({ node: sku }) => (
                <SkuCard key={sku.id} sku={sku} stripe={this.state.stripe} />
              ))}
            </div>
          </div>
        )}
      />
    );
  }
}

export default Skus;
