/* eslint-disable react/prop-types */
import React from "react";
// import propTypes from "prop-types";
import Img from "gatsby-image";
//import Checkout from "../../components/checkout";
import { Link } from "gatsby";

const cardStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
  padding: "1rem",
  marginBottom: "1rem",
  boxShadow: "5px 5px 25px 0 rgba(46,61,73,.2)",
  backgroundColor: "#fff",
  borderRadius: "6px",
  maxWidth: "300px",
};
const buttonStyles = {
  fontSize: "14px",
  textAlign: "center",
  color: "#000",
  outline: "none",
  padding: "12px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(255, 178, 56)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
};

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2);
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  });
  return numberFormat.format(price);
};

function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

const SkuCard = class extends React.Component {
  async redirectToCheckout(event, sku, quantity = 1) {
    event.preventDefault();
    const { error } = await this.props.stripe.redirectToCheckout({
      items: [{ sku, quantity }],
      // successUrl: `http://localhost:8000/`,
      // cancelUrl: `http://localhost:8000/`
      successUrl: `${process.env.GATSBY_WEBSITE_ROOT}/success`,
      cancelUrl: `${process.env.GATSBY_WEBSITE_ROOT}/cancel`,
      shippingAddressCollection: {
        allowedCountries: ["US"],
      },
    });

    if (error) {
      console.warn("Error:", error);
    }
  }

  render() {
    const sku = this.props.sku;
    const slug = string_to_slug(sku.attributes.name);
    //console.log(this.props);

    return (
      <div style={cardStyles}>
        <h2>{sku.attributes.name}</h2>
        <Img
          fixed={sku.localFiles[0].childImageSharp.fixed}
          alt={sku.attributes.name}
        />
        <p>Price: {formatPrice(sku.price, sku.currency)}</p>

        <Link to={`../pages/details/${slug}`} style={buttonStyles}>
          Details
        </Link>
        <br />
        <button
          style={buttonStyles}
          onClick={(event) => this.redirectToCheckout(event, sku.id)}
        >
          BUY ME
        </button>
      </div>
    );
  }
};

//console.log(SkuCard);

SkuCard.displayName = "SkuCard";

// SkuCard.propTypes = {
//   sku: propTypes.string
// };

export default SkuCard;
