import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Skus from "../components/Products/Records";

const Records = () => (
  <Layout>
    <SEO
      keywords={[
        `punk records`,
        `punkrockvideos`,
        `punkrockvideos.com`,
        `punk`,
        `punkrock`,
        `records`,
        `poppunk`,
        `emo`,
        `garage rock`,
        `7 inchs`,
        `vinyl records`,
        `pop punk`,
        `out of print records`,
        `color vinyl`,
      ]}
      title="Records"
    />
    <h1 className="mt-1 font-semibold text-lg leading-tight truncate">
      Records
    </h1>
    <p>
      1990s 7 inch punk records. All records are out of print. All prices
      include shipping only US. If you have any questions contact me via the
      webform on the contact page.
    </p>
    <Skus />
  </Layout>
);

export default Records;
